import React from 'react';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import { Detail, Seo } from 'components';

export default ({
  data: {
    page: {
      frontmatter: { title, redirect, description },
      body
    }
  }
}) => {
  if (redirect && process.browser) {
    window.location.replace(redirect);
  }

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <Seo title={title} description={description} />
      <Detail title={title}>{body}</Detail>
    </>
  );
};

export const pageQuery = graphql`
  query($id: String!) {
    page: mdx(id: {eq: $id}) {
      frontmatter {
        title
        redirect
        description
      }
      body
    }
  }
`;
